body {
  margin: 0;
}

.SignInWindow {
  display: block;
  background-image: url(../../static/tower-login.jpg);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 85px 0px 50px;
  min-height: 305px;
}

.SignInWindow__window {
  margin: auto;
  width: 480px;
}