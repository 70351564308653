.Header__button {
  padding-top: 0px;
  padding-bottom: 0px;
  width: 60px;
  min-width: 60px;
  text-transform: uppercase;
  height: 100%;
}

@media (min-width: 600px) {
  .Header__toolbar {
    height: 64px;
    min-height: 64px;
  }
}